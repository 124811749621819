<template>

  <div class="row" ref="preworkForm">
    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                      vid="title"
                      rules="required"
                      name="The Title"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Title"
                                name="title"
                                fou
                                v-model="formData.title">
                      </fg-input>
                    </ValidationProvider>
                    <ValidationProvider
                      vid="sub_title"
                      rules=""
                      name="The Sub Title"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Sub Title"
                                name="sub_title"
                                fou
                                v-model="formData.sub_title">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                      vid="forum_id"
                      rules="required"
                      name="The Edition"
                      v-slot="{ passed, failed,errors }">
                      <fg-select
                        name="forum_id"
                        size="large"
                        filterable
                        clearable
                        :error="failed ? errors[0]: null"
                        placeholder="Select Edition"
                        :input-classes="'select-default'"
                        :label="'Editions'"
                        :list="builderData.forumList"
                        :listItemLabel="'name'"
                        :listItemValue="'id'"
                        v-model="formData.forum_id">
                      </fg-select>
                    </ValidationProvider>
                  </div>
                </div>


                <fieldset>
                  <legend>Files</legend>
                  <div class="col-12">
                    <template class="row" v-for="(file,fileIndex) in formData.files">
                      <div class="row">
                        <div class="col-md-12">
                          <fg-input type="text"
                                    label="File Name"
                                    name="file_name"
                                    fou
                                    v-model="formData.files[fileIndex].file_name">
                          </fg-input>
                          <ValidationProvider
                            vid="file_type"
                            rules="required"
                            name="The File Type"
                            v-slot="{ passed, failed,errors }">
                            <fg-select
                              name="file_type"
                              size="large"
                              filterable
                              clearable
                              placeholder="File Type"
                              :error="failed ? errors[0]: null"
                              :input-classes="'select-default'"
                              :label="'File Type'"
                              :list="builderData.fileTypes"
                              :listItemLabel="'name'"
                              :listItemValue="'value'"
                              v-model="formData.files[fileIndex].file_type">
                            </fg-select>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group" v-if="formData.files[fileIndex].file_type === 'PDF'">
                          <label>File</label>
                          <el-tooltip placement="right"  >
                            <div slot="content">
                              (PDF only)
                            </div>
                            <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                          </el-tooltip>
                          <single-file-uploader-file-manager
                            :preview-width="'200px'"
                            :preview-height="'200px'"
                            :fileType="'document'"
                            v-model="formData.files[fileIndex].pdf"
                            :accepted-files="pdfExtensions">
                          </single-file-uploader-file-manager>
                        </div>
                        <div class="col-md-12" v-if="formData.files[fileIndex].file_type === 'VIDEO'">
                          <ValidationProvider
                            vid="video_type"
                            rules=""
                            name="The Video Type"
                            v-slot="{ passed, failed,errors }">
                            <fg-select
                              name="video_type"
                              size="large"
                              filterable
                              clearable
                              placeholder="Video Type"
                              :error="failed ? errors[0]: null"
                              :input-classes="'select-default'"
                              :label="'Video Type'"
                              :list="builderData.videoTypes"
                              :listItemLabel="'label'"
                              :listItemValue="'value'"
                              v-model="formData.files[fileIndex].video_type">
                            </fg-select>
                          </ValidationProvider>
                          <div class="form-group" v-if="formData.files[fileIndex].video_type === 'UPLOADED'">
                            <label>Uploaded Video</label>
                            <el-tooltip placement="right" v-if="true">
                              <div slot="content">Video</div>
                              <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                            </el-tooltip>
                            <single-file-uploader-file-manager
                              :preview-width="'200px'"
                              :preview-height="'200px'"
                              :maxFileSize="50000000"
                              :fileType="'video'"
                              :accepted-files="videosExtensions"
                              v-model="formData.files[fileIndex].uploaded_video"
                            >
                            </single-file-uploader-file-manager>
                          </div>
                          <div class="form-group" v-if="formData.files[fileIndex].video_type == 'EMBEDDED'">
                            <el-tooltip placement="right" v-if="true">
                              <div slot="content">Embedded Video</div>
                              <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                            </el-tooltip>
                            <ValidationProvider
                              vid="embedded_video"
                              rules=""
                              name="The Embedded Video"
                              v-slot="{ passed, failed,errors }">
                              <fg-input type="text"
                                        :error="failed ? errors[0]: null"
                                        label="Embedded Video"
                                        name="embedded_video"
                                        fou
                                        v-model="formData.files[fileIndex].embedded_video">
                              </fg-input>
                            </ValidationProvider>
                            <div v-html="formData.files[fileIndex].embedded_video">
                            </div>
                          </div>
                          <div class="form-group" v-if="formData.files[fileIndex].video_type == 'LINK'">
                            <fg-input type="text"
                                      label="Link"
                                      name="link"
                                      fou
                                      v-model="formData.files[fileIndex].link">
                            </fg-input>
                          </div>
                        </div>
                        <div class="col-md-12" v-if="formData.files[fileIndex].file_type === 'WEBPAGE'">
                          <ValidationProvider
                            vid="webpage"
                            rules="required"
                            name="The Webpage"
                            v-slot="{ passed, failed,errors }">
                            <fg-input type="text"
                                      :error="failed ? errors[0]: null"
                                      label="Webpage"
                                      name="webpage"
                                      fou
                                      v-model="formData.files[fileIndex].webpage">
                            </fg-input>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="col-sm-1 align-self-center mt-3">
                        <l-button
                          @click="removeFile(formData.files, fileIndex)"
                          type="danger" size="sm">
                          <i class="fa fa-trash"></i>
                        </l-button>
                      </div>
                    </template>
                    <l-button
                      class="mt-3"
                      type="success"
                      @click="addNewFile(formData.files)"
                      size="sm">
                      <i class="fa fa-plus"></i>
                    </l-button>
                  </div>
                </fieldset>


                <fieldset>
                  <legend>List Items</legend>
                  <div class="col-12">
                    <template class="row" v-for="(listItem,listItemIndex) in formData.list_items">
                      <fg-input type="text"
                                :key="listItemIndex"
                                :label="'Item '+(listItemIndex+1)"
                                v-model="formData.list_items[listItemIndex]">
                      </fg-input>
                      <div class="col-sm-1 align-self-center mt-3">
                        <l-button
                          @click="removeItem(formData.list_items, listItemIndex)"
                          type="danger" size="sm">
                          <i class="fa fa-trash"></i>
                        </l-button>
                      </div>
                    </template>
                    <l-button
                      class="mt-3"
                      type="success"
                      @click="addNewItem(formData.list_items)"
                      size="sm">
                      <i class="fa fa-plus"></i>
                    </l-button>
                  </div>
                </fieldset>






                <div class="form-group pt-4">
                  <label>Is Active</label>&nbsp;
                  <l-switch v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>
                <div class="form-group pt-4">
                  <label>Is Optional</label>&nbsp;
                  <l-switch v-model="formData.is_optional">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>
            </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/pre-works/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import {Option, Select, Tooltip} from "element-ui";
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import SingleFileUploaderFileManager from "../../components/FileManager/SingleFileUploaderFileManager";



extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    SingleFileUploaderFileManager,
    PrimeUploader,
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      imagesExtensions: this.getImagesExtensions(),
      pdfExtensions: this.getPdfExtensions(),
      videosExtensions: this.getVideoExtensions(),
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      formData: {
        title: "",
        sub_title: "",
        file_type: "",
        pdf: "",
        video: '',
        webpage: '',
        is_active: true,
        forum_id: '',
        is_optional: false,
        list_items: [],
        files: [],
      },
      builderData: {
        forumList: [],
        fileTypes: [],
        videoTypes: [],
      },

    };
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.preWorkForm
    });

    let data = {}
    this.axios.post("pre-works/builder", data).then((response) => {
      this.builderData.forumList = response.data.forums;
      this.builderData.fileTypes = response.data.fileTypes;
      this.builderData.videoTypes = response.data.videoTypes;
      this.formData.forum_id = response.data.currentForum ? response.data.currentForum.id : '';

      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Pre-work";
        this.getPreWork();
      } else {
        this.editMode = false;
        this.formTitle = "Add Pre-work";
        this.loader.hide();
      }
    }).catch((error) => {
      console.error(error);
    })
  },
  methods: {
    getPreWork() {
      let data = {
        'id': this.id,
      };
      this.axios.post("pre-works/get", data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Pre-work Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      if (this.editMode === true) {
        request = this.axios.put("pre-works/update/" + this.id, this.formData);
        successMessage = "Pre-work Updated Successfully";
      } else {
        request = this.axios.post("pre-works/create", this.formData);
        successMessage = "Pre-work Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/pre-works/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    addNewItem(listItems) {
      listItems.push('');
    },
    removeItem(listItems, index) {
      listItems.splice(index, 1);
    },

    addNewFile(files) {
      files.push({file_name: '', link: '', file_type: '', pdf: '', webpage: '', video_type: '', uploaded_video: '', embedded_video: ''});
    },
    removeFile(files, index) {
      files.splice(index, 1);
    },
  },
}
</script>
